import { request } from '@/utils/request'

/**
 * 
 * 结清报表
 */

//结清报表
export function settleList() {
    return request({
        url: '/api/manage/settle/query',
        method: 'get'
    })
}

/**
 * 
 * 资产占比
 */

//资产占比
export function ratioList() {
    return request({
        url: '/api/manage/ratio/query',
        method: 'get'
    })
}

//下载资产占比excel
export function downRatio() {
    return request({
        url: '/api/manage/ratio/exportExcel',
        method: 'get',
        responseType: 'blob', // 服务器返回的数据类型
        timeout: 500 * 100

    })
}

/**
 * 结清收益
 */

//全部结清
export function profitList(pagenum, size) {
    return request({
        url: `/api/manage/profit/queryByAll/${pagenum}/${size}`,
        method: 'get'
    })
}

//下载全部结清收益excel
export function downProfit() {
    return request({
        url: '/api/manage/profit/exportExcel',
        method: 'get',
        responseType: 'blob', // 服务器返回的数据类型
        timeout: 500 * 1000
    })
}

//买断结清
export function ByBoughtProfitList(pagenum, size) {
    return request({
        url: `/api/manage/profit/queryByBought/${pagenum}/${size}`,
        method: 'get'
    })
}

//下载已买断结清收益excel
export function downProfitByBought() {
    return request({
        url: '/api/manage/profit/exportExcelByBought',
        method: 'get',
        responseType: 'blob', // 服务器返回的数据类型
        timeout: 500 * 1000
    })
}


//已处置结清

export function ByDisposedProfitList(pagenum, size) {
    return request({
        url: `/api/manage/profit/queryByDisposed/${pagenum}/${size}`,
        method: 'get'
    })
}

//下载已处置结清收益excel
export function downProfitByDisposed() {
    return request({
        url: '/api/manage/profit/exportExcelByDisposed',
        method: 'get',
        responseType: 'blob', // 服务器返回的数据类型
        timeout: 500 * 1000
    })
}