<template>
  <div id='settlereport'>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item class="myColor1">首页</el-breadcrumb-item>
      <el-breadcrumb-item class="myColor2">结清报表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-row :gutter="40">
      <el-col :span="5">
        <el-card shadow="hover">
          <div class="totalBox">
            <div class="title">结清总数</div>
            <div class="totalnumber">
              <span class="numbers">{{total}}</span>
              <span class="yuan">辆</span>
            </div>
          </div>
        </el-card>
      </el-col>
      <!-- <el-col :span="5">
        <el-card shadow="hover">
          <div class="totalBox">
            <div class="title">6个月内结清数</div>
            <div class="totalnumber">
              <span class="numbers">{{lt6Count}}</span>
              <span class="yuan">辆</span>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card shadow="hover">
          <div class="totalBox">
            <div class="title">6个月以上结清数</div>
            <div class="totalnumber">
              <span class="numbers">{{gt6Count}}</span>
              <span class="yuan">辆</span>
            </div>
          </div>
        </el-card>
      </el-col> -->
    </el-row>
    <div class="tablebox">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-card>
            <el-divider style="font-weight:900" content-position="left">月结清明细</el-divider>
            <div>
              <el-table stripe :data="tableData" :span-method="objectSpanMethod" border style="width: 100%" :header-cell-style="headerStyle">
                <el-table-column align="center" label="时间">
                  <el-table-column align="center" width="120">
                    <template slot-scope="scope">
                      {{scope.row.year}}年
                    </template>
                  </el-table-column>
                  <el-table-column align="center">
                    <template slot-scope="scope">
                      {{scope.row.month}}月
                    </template>
                  </el-table-column>
                </el-table-column>
                <el-table-column align="center" prop="count" label="数量/月">
                </el-table-column>
                <el-table-column align="center" prop="allcount" label="数量/年">
                </el-table-column>
              </el-table>
            </div>
          </el-card>
        </el-col>
        <el-col :span="12">
          <el-card>
            <el-divider style="font-weight:900" content-position="left">公司结清明细</el-divider>
            <div v-for="item,index in companytableData" :key="index" class="companytablebox">
              <div class="companytitle">
                <div class="company"><i class="el-icon-office-building"></i>{{item.companyName}}</div>
                <div>总数量:{{item.companynumber}}</div>
              </div>
              <el-table stripe :data="item.list" :span-method="(row, column, rowIndex, columnIndex)=>{return companyobjectSpanMethod({row, column, rowIndex, columnIndex}, item.mergeList)}" border
                style="width: 100%" :header-cell-style="headerStyle">
                <el-table-column align="center" label="时间">
                  <el-table-column align="center" width="120">
                    <template slot-scope="scope">
                      {{scope.row.year}}年
                    </template>
                  </el-table-column>
                  <el-table-column align="center">
                    <template slot-scope="scope">
                      {{scope.row.month}}月
                    </template>
                  </el-table-column>
                </el-table-column>
                <el-table-column align="center" prop="count" label="数量/月">
                </el-table-column>
                <el-table-column align="center" prop="yearnum" label="数量/年">
                </el-table-column>
              </el-table>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
    
<script>
import { settleList } from "@/api/settle.js";
export default {
  name: "",
  data() {
    return {
      tableData: [],
      mergeList: [],
      companytableData: [],
      total: "", //结清总数
      lt6Count: "", //6个月以内数
      gt6Count: "", //6个月以上数
      settleDetailList: [], //结清详细公司
      settleDetailListGroupYearAndMonth: [], //结清详细(年/月)
    };
  },
  created() {
    // let tableData = [];
    // let mergeList = [];
    // this.tableData.forEach((element) => {
    //   let year = element.years;
    //   let total = element.total;
    //   let columns = element.months.length;
    //   element.months.map((ele) => {
    //     let data = {
    //       ...ele,
    //       year,
    //       total,
    //       columns,
    //     };
    //     // console.log(data);
    //     tableData.push(data);
    //   });
    //   mergeList.push(columns);
    // });
    // this.tableData = tableData;
    // this.mergeList = mergeList;
    // // ---------------------------------------------------
    // let companytableData = [];
    // this.companytableData.forEach((com) => {
    //   //   console.log(com);
    //   let companydata = [];
    //   let commergeList = [];
    //   com.companys.forEach((y) => {
    //     let year = y.years;
    //     let total = y.total;
    //     let columns = y.months.length;
    //     let tableDatas = [];
    //     y.months.map((ele) => {
    //       //   console.log(ele);
    //       let data = {
    //         ...ele,
    //         year,
    //         total,
    //         columns,
    //       };
    //       //   console.log(data);
    //       tableDatas.push(data);
    //     });
    //     // console.log(tableDatas);
    //     console.log(columns);
    //     companydata = companydata.concat(tableDatas);
    //     commergeList.push(columns);
    //   });
    //   //   console.log(companydata, commergeList);
    //   let companysdata = {
    //     companyname: com.company,
    //     companynumber: com.number,
    //     companydata,
    //     commergeList,
    //   };
    //   companytableData.push(companysdata);
    // });
    // // console.log(companymergeList);
    // this.companytableData = companytableData;

    this.getlist();
  },
  methods: {
    getlist() {
      settleList().then((res) => {
        console.log(res);
        this.total = res.data.total;
        this.lt6Count = res.data.lt6Count;
        this.gt6Count = res.data.gt6Count;
        this.settleDetailList = res.data.settleDetailList;
        this.settleDetailListGroupYearAndMonth =
          res.data.settleDetailListGroupYearAndMonth;

        //测试数据
        // let arr = [
        //   {
        //     companyName: "宁波卓领",
        //     year: 2022,
        //     month: 10,
        //     count: 1,
        //   },
        //   {
        //     companyName: "宁波卓领",
        //     year: 2021,
        //     month: 10,
        //     count: 1,
        //   },
        //   {
        //     companyName: "宁波卓领",
        //     year: 2021,
        //     month: 5,
        //     count: 1,
        //   },
        //   {
        //     companyName: "绍兴金寓宏",
        //     year: 2022,
        //     month: 12,
        //     count: 1,
        //   },
        //   {
        //     companyName: "绍兴金寓宏",
        //     year: 2021,
        //     month: 11,
        //     count: 1,
        //   },
        //   {
        //     companyName: "绍兴金寓宏",
        //     year: 2021,
        //     month: 6,
        //     count: 1,
        //   },
        //   {
        //     companyName: "绍兴金寓宏",
        //     year: 2020,
        //     month: 5,
        //     count: 2,
        //   },,
        //   {
        //     companyName: "绍兴金寓宏",
        //     year: 2020,
        //     month: 5,
        //     count: 1,
        //   }
        // ];

        let tableData = []; //分组表格数据--计算总数量,分组合并
        let mergeList = []; //合并行结果
        let arr = res.data.settleDetailListGroupYearAndMonth;
        tableData = this.splitArray(arr);
        // console.log(tableData);
        tableData.map((val) => {
          mergeList.push(val.list.length);
          let Counts = 0;
          val.list.map((num) => {
            Counts += num.count;
          });
          //对源数据添加求和结果
          if (mergeList.length === 1) {
            // console.log("指定索引", 0, Counts);
            arr[0].allcount = Counts;
          } else if (mergeList.length > 1) {
            let allindex = 0; //计算length-1前索引值和
            for (var i = 0; i < mergeList.length - 1; i++) {
              allindex += mergeList[i];
            }
            // console.log(allindex, Counts);
            arr[allindex].allcount = Counts;
          }
        });
        // console.log(mergeList, tableData, arr);
        this.tableData = arr;
        this.mergeList = mergeList;

        /**计算公司合计数据--------------------------------------------------------- */
        let companytableData = [];
        let companyarr = res.data.settleDetailList;
        companytableData = this.companySplitArray(companyarr);
        // console.log(companytableData);
        companytableData.forEach((ele) => {
          let commerget = [];
          let companynum = 0; //公司总和
          ele.list.map((num) => {
            companynum += num.count;
          });
          ele.companynumber = companynum;
          let comlist = this.splitArray(ele.list);
          comlist.map((val) => {
            commerget.push(val.list.length);

            console.log(val);
            //计算所有数量和
            let yearnum = 0;
            val.list.map((num) => {
              yearnum += num.count;
            });
            console.log(ele, yearnum, commerget);
            let comList = ele.list;
            // //对源数据添加求和结果
            if (commerget.length === 1) {
              // console.log("指定索引", 0, Counts);
              comList[0].yearnum = yearnum;
            } else if (commerget.length > 1) {
              let allindex = 0; //计算length-1前索引值和
              for (var i = 0; i < commerget.length - 1; i++) {
                allindex += commerget[i];
              }
              comList[allindex].yearnum = yearnum;
            }
          });
          // console.log(ele.companyName, comlist, commerget);
          //添加合并分组数据
          ele.mergeList = commerget;
        });
        console.log("最终结果", companytableData);
        this.companytableData = companytableData;
      });
    },
    headerStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 1) {
        return { display: "none" };
      }
    },
    //按属性年(year)分割数组
    splitArray(arr) {
      let dataArr = [];
      arr.map((mapItem) => {
        if (dataArr.length == 0) {
          dataArr.push({ year: mapItem.year, list: [mapItem] });
        } else {
          let res = dataArr.some((item) => {
            //判断相同日期，有就添加到当前项
            // console.log(item);
            if (item.year == mapItem.year) {
              item.list.push(mapItem);
              return true;
            }
          });
          if (!res) {
            //如果没找相同日期添加一个新对象
            dataArr.push({ year: mapItem.year, list: [mapItem] });
          }
        }
      });
      return dataArr;
    },
    //按公司名称分割数组
    companySplitArray(arr) {
      let dataArr = [];
      arr.map((mapItem) => {
        if (dataArr.length == 0) {
          dataArr.push({ companyName: mapItem.companyName, list: [mapItem] });
        } else {
          let res = dataArr.some((item) => {
            //判断相同公司名称，有就添加到当前项
            // console.log(item);
            if (item.companyName == mapItem.companyName) {
              item.list.push(mapItem);
              return true;
            }
          });
          if (!res) {
            //如果没找相同日期添加一个新对象
            dataArr.push({ companyName: mapItem.companyName, list: [mapItem] });
          }
        }
      });
      return dataArr;
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      let list = [3, 2, 3];
      list = this.mergeList;
      //   console.log(list);
      let s = 0;
      if (columnIndex === 0 || columnIndex === 3) {
        // console.log(row, column, rowIndex, columnIndex);
        for (var i = 0; i < list.length; i++) {
          //   console.log(list[i],i);
          s += list[i];
          if (i == 0) {
            if (rowIndex < list[i]) {
              if (rowIndex % list[i] === 0) {
                return {
                  rowspan: list[i],
                  colspan: 1,
                };
              } else {
                return {
                  rowspan: 0,
                  colspan: 0,
                };
              }
            }
          } else {
            if (rowIndex >= s - list[i] && rowIndex < s) {
              if ((rowIndex - (s - list[i])) % list[i] === 0) {
                return {
                  rowspan: list[i],
                  colspan: 1,
                };
              } else {
                return {
                  rowspan: 0,
                  colspan: 0,
                };
              }
            }
          }
        }

        // if (rowIndex < 3) {
        //   if (rowIndex % 3 === 0) {
        //     return {
        //       rowspan: 3,
        //       colspan: 1,
        //     };
        //   } else {
        //     return {
        //       rowspan: 0,
        //       colspan: 0,
        //     };
        //   }
        // }
        // if (rowIndex >= 3 && rowIndex < 5) {
        //   if ((rowIndex - 3) % 2 === 0) {
        //     return {
        //       rowspan: 2,
        //       colspan: 1,
        //     };
        //   } else {
        //     return {
        //       rowspan: 0,
        //       colspan: 0,
        //     };
        //   }
        // }
        // if (rowIndex >= 5 && rowIndex < 9) {
        //   if ((rowIndex - 5) % 4 === 0) {
        //     return {
        //       rowspan: 4,
        //       colspan: 1,
        //     };
        //   } else {
        //     return {
        //       rowspan: 0,
        //       colspan: 0,
        //     };
        //   }
        // }
      }
    },
    //循环--表格(自定义合并表格行)
    companyobjectSpanMethod(
      { row, column, rowIndex, columnIndex },
      commergeList
    ) {
      let list = [3, 2, 3];
      list = commergeList;
      // console.log(list);
      let s = 0;
      //   console.log(row, column, rowIndex, columnIndex);
      let columnIndexs = row.columnIndex;
      let rowIndexs = row.rowIndex;

      if (columnIndexs === 0 || columnIndexs === 3) {
        console.log("循环");
        for (var i = 0; i < list.length; i++) {
          s += list[i];
          if (i == 0) {
            if (rowIndexs < list[i]) {
              if (rowIndexs % list[i] === 0) {
                return {
                  rowspan: list[i],
                  colspan: 1,
                };
              } else {
                return {
                  rowspan: 0,
                  colspan: 0,
                };
              }
            }
          } else {
            if (rowIndexs >= s - list[i] && rowIndexs < s) {
              if ((rowIndexs - (s - list[i])) % list[i] === 0) {
                return {
                  rowspan: list[i],
                  colspan: 1,
                };
              } else {
                return {
                  rowspan: 0,
                  colspan: 0,
                };
              }
            }
          }
        }
      }
    },
  },
};
</script>
    
<style lang='less' scoped>
#settlereport {
  padding: 60px 20px 20px 20px;
  .totalBox {
    padding: 0 15px;
    .numbers {
      font-size: 30px;
      line-height: 60px;
      font-weight: 900;
    }
    .yuan {
      font-weight: 900;
      margin: 0 4px;
    }
  }
  .tablebox {
    margin-top: 20px;
    .companytablebox {
      margin-bottom: 20px;
      box-shadow: 1px 0 3px rgb(160, 157, 157);
      border-radius: 4px;
    }
    /deep/ .el-divider__text.is-left {
      font-weight: 900;
      font-size: 16px;
    }
    .companytitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      font-weight: 600;
      padding: 10px 40px 5px 10px;
      .company {
        line-height: 35px;
        // position: relative;
        // &:before {
        //   top: 12px;
        //   left: -15px;
        //   position: absolute;
        //   display: block;
        //   width: 10px;
        //   height: 10px;
        //   border-radius: 50%;
        //   background: #474747;
        //   content: "";
        // }
        i {
          font-size: 18px;
          margin: 0 5px;
          font-weight: 700;
        }
      }
    }
  }
}
</style>