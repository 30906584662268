<template>
  <div id='propertypercent'>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item class="myColor1">首页</el-breadcrumb-item>
      <el-breadcrumb-item class="myColor2">资产占比</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <div class="downreport">
        <el-button type="warning" round size="mini" icon="el-icon-download" @click="DownExcel()">导出</el-button>
      </div>
      <div class="tablebox">
        <el-divider content-position="left">资产中存量占比</el-divider>
        <el-table stripe :data="tableData" style="width: 100%" border :header-cell-style="{color:'#333',fontFamily:'MicrosoftYaHeiUI',fontSize:'14px',fontWeight:900,backgroundColor:'#eff3f8'}">
          <el-table-column align="center" label="车辆状态">
            <template slot-scope="scope">
              {{scope.row.carStatus}}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="total" label="数量(辆)">
          </el-table-column>
          <el-table-column align="center" label="占比">
            <template slot-scope="scope">
              {{scope.row.ratio.slice(0, -2)}}%
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="tablebox">
        <el-divider content-position="left">全部占比</el-divider>
        <el-table stripe :data="alltableData" style="width: 100%" border :header-cell-style="{color:'#333',fontFamily:'MicrosoftYaHeiUI',fontSize:'14px',fontWeight:900,backgroundColor:'#eff3f8'}">
          <el-table-column align="center" prop="carStatus" label="车辆状态">
          </el-table-column>
          <el-table-column align="center" prop="total" label="数量(辆)">
          </el-table-column>
          <el-table-column align="center" label="占比">
            <template slot-scope="scope">
              {{scope.row.ratio}}%
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>
  </div>
</template>
    
<script>
import { ratioList,downRatio } from "@/api/settle.js";
export default {
  name: "",
  data() {
    return {
      //存量占比数据
      tableData: [],
      //全部占比数据
      alltableData: [],
      optionsStatus: ["存续", "待处置", "已拖回", "已抵押", "非法过户"], //存量状态
      iStatus: ["已买断", "已处置"], //非存量状态
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      ratioList().then((res) => {
        console.log(res);
        let yuantabledata = res.data;

        let dataList = []; //源数据状态集
        let iscunData = []; //非存量表格数据

        let cunStatus = []; //当前存量状态
        let iscunStatus = []; //当前非存量状态
        let allCount = 0; //当前资产存量总数
        /**
         * 计算全部得到的状态值---不够去补全
         * 合计总资产--总占比计算用
         */
        res.data.forEach((ele) => {
          dataList.push(ele.carStatus);
          console.log(ele.total);
          allCount += ele.total;
        });
        console.log(dataList);

        //1,移除非存量状态
        dataList.forEach((item, index) => {
          if (this.iStatus.includes(item) && dataList.includes(item)) {
            console.log("非存量----", item);
            iscunStatus.push(item);
            yuantabledata.forEach((ele, index) => {
              if (ele.carStatus == item) {
                // console.log("非存量----", ele);
                iscunData.push(ele); //存储非存量数据---合并到总占比
                yuantabledata.splice(index, 1); //移除非存量数据
              }
            });
          } else {
            console.log("存量----" + item);
            //存储存量状态--补全没有的状态
            cunStatus.push(item);
          }
        });
        console.log(cunStatus);
        //补全存量状态
        let newdata = this.poliStatus(this.optionsStatus, cunStatus);
        console.log("存量缺少的状态", newdata);
        //保存存量数据
        this.tableData = yuantabledata.concat(newdata);

        //------------------------
        //补全非存量状态
        let isnewdata = this.poliStatus(this.iStatus, iscunStatus);
        let isalltable = isnewdata.concat(iscunData);
        console.log(isalltable);
        //重新计算总占比
        isalltable.forEach((it) => {
          it.ratio =
            this.irrFormatt((it.total / allCount) * 100) == ""
              ? "0.00"
              : this.irrFormatt((it.total / allCount) * 100);
        });
        console.log(isalltable);
        //计算总存量的数量
        let allcuntotal = 0;
        this.tableData.forEach((element) => {
          allcuntotal += element.total;
        });
        console.log(allcuntotal, allCount);
        //总存量数据
        let allcundata = [
          {
            carStatus: "存量",
            total: allcuntotal,
            ratio: this.irrFormatt((allcuntotal / allCount) * 100),
          },
        ];
        console.log("总占比", isalltable, allcundata);

        this.alltableData = allcundata.concat(isalltable);
      });
    },
    //对比数组是否缺少
    poliStatus(standard, val) {
      console.log(standard, val);
      let result = [];
      standard.forEach((item) => {
        if (standard.includes(item) && !val.includes(item)) {
          let items = {
            carStatus: item,
            total: 0,
            ratio: "00.0000",
          };
          result.push(items);
        }
      });
      console.log(result);
      return result;
    },
    //保留两位小数-补零
    irrFormatt(num) {
      // console.log(num);
      if (num == null || num == "") return "";
      var value = Math.floor(parseFloat(num) * 100) / 100;
      var s = value.toString().split(".");
      if (s.length == 1) {
        value = value.toString() + ".00";
        return value;
      }
      if (s.length > 1) {
        if (s[1].length < 2) {
          value = value.toString() + "0";
        }
        return value;
      }
    },

    // 导出报表
    DownExcel() {
      var _this = this;
      downRatio()
        .then((res) => {
          console.log(res);
          // 1,创建blob对象
          const blob = new Blob([res], {
            type: "application/vnd.ms-excel",
          });
          const newTime = _this.$moment().format("YYYYMMDDkkmmss");
          const fileName = "propertypercent_" + newTime; // 文件名

          // 2, 创建类文件对象, 导入blob数据源
          const url = window.URL.createObjectURL(blob);

          // 生成a标签存入body,利用herf属性下载
          const dom = document.createElement("a");
          dom.style.display = "none";
          dom.href = url;
          dom.setAttribute("download", fileName);
          document.body.appendChild(dom);
          dom.click();

          this.$message.success("正在下载...");
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
    
<style lang='less' scoped>
#propertypercent {
  padding: 60px 20px 20px 20px;
  /deep/ .el-divider__text.is-left {
    font-weight: 900;
    font-size: 16px;
  }
  /deep/ .el-divider--horizontal {
    margin: 30px 0;
  }
  .tablebox {
    width: 80%;
  }
}
</style>